import React, { useState } from 'react';
import { Typography, Stack, Box, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackBars, useCreateUpdateMailTemplate } from '../../hooks';
import UploadDropZoneDialogContainer from '../containers/UploadDropZoneDialogContainer';
import { ADD_CONTROL_GROUPS_BY_FILE, DELETE_CONTROL_GROUPS_BY_FILE } from '../../graphql';

const UploadControlGroupForm = ({
  mailParameterId, companyId, createBlackList, setShowUploadFile,
}) => {
  const [files, setFiles] = useState([]);
  const { addAlert } = useSnackBars();
  const [mailParamId, setMailParamId] = useState(mailParameterId);

  const [addControlGroupsByFile, { loading: loadingAssign }] = useMutation(
    ADD_CONTROL_GROUPS_BY_FILE,
    {
      variables: { mailParameterId: mailParamId, companyFile: files[0] },
      onCompleted: (data) => addAlert({
        id: 'add-control-group-by-file',
        message: `Se asignaron ${data.addControlGroupsByFile.updatedGroups} empresas a esta plantilla`,
      }),
      onError: (err) => addAlert({
        id: 'add-control-group--by-file-error',
        message: err.message,
      }),
    },
  );

  const [deleteControlGroupsByFile, { loading: loadingDelete }] = useMutation(
    DELETE_CONTROL_GROUPS_BY_FILE,
    {
      variables: { mailParameterId: mailParamId, companyFile: files[0] },
      onCompleted: (data) => {
        const nCompanies = data.deleteControlGroupsByFile.deletedGroups;
        addAlert({
          id: 'delete-control-group-by-file',
          message: createBlackList
            ? `A ${nCompanies} ya no se les enviará esta plantilla`
            : `${nCompanies} empresas ya no están en la blacklist de esta plantilla`,
        });
      },
      onError: (err) => addAlert({
        id: 'delete-control-group--by-file-error',
        message: err.message,
      }),
    },
  );

  const { createUpdateMailTemplate } = useCreateUpdateMailTemplate({
    editMode: false,
    companyId,
    addControlGroupBlackList: createBlackList,
    addControlGroup: null,
    addControlGroupsByFile,
    setMailParamId,
    setSelectedMailParam: null,
  });

  const handleAssign = () => {
    if (createBlackList) {
      const submissionValues = {
        version: 'BLACKLIST',
        mailParameter: mailParameterId,
      };
      createUpdateMailTemplate({ variables: submissionValues });
    } else {
      addControlGroupsByFile();
    }
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Asignar empresas a esta combinación</Typography>
        <IconButton
          size="small"
          onClick={() => setShowUploadFile(false)}
          color="success"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Stack>
      <Typography variant="body">
        Subir excel con columna con los ruts de empresas
        con encabezado &quot;rut&quot;.
        Se les mandará de ahora
        en adelante esta versión del mail en vez del default, A
      </Typography>
      <Box>
        <UploadDropZoneDialogContainer files={files} setFiles={setFiles} />
      </Box>
      <Stack spacing={3} direction="row" alignItems="center" justifyContent="center">
        <LoadingButton
          id="bulk-assign-control-group-button"
          onClick={() => handleAssign()}
          disabled={!files.length || loadingDelete}
          loading={loadingAssign}
          color="success"
          size="small"
          variant="outlined"
        >
          Asignar
        </LoadingButton>
        <LoadingButton
          id="bulk-delete-control-group-button"
          onClick={() => deleteControlGroupsByFile()}
          disabled={!files.length || loadingAssign}
          loading={loadingDelete}
          color="error"
          size="small"
          variant="outlined"
        >
          Desasignar
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

UploadControlGroupForm.propTypes = {
  mailParameterId: PropTypes.string.isRequired,
  companyId: PropTypes.string,
  createBlackList: PropTypes.bool,
  setShowUploadFile: PropTypes.func.isRequired,
};

UploadControlGroupForm.defaultProps = {
  companyId: null,
  createBlackList: false,
};

export default UploadControlGroupForm;
